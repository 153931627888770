<template>
  <div id="platformData">
    <div id="bgBox">
      <img :src="url" />
    </div>
    <div style="position: relative">
      <div id="title">平台数据</div>
      <div class="header">
        <div class="moduleBox">
          <div class="title">平台总计</div>
          <div class="contentBox">
            <div class="sonBox" style="min-width: 30%">
              <div class="title">金叶子总计</div>
              <div class="nuber">
                {{ obj.available ? keepTwoDecimalFull(obj.available) : "--"
                }}<span class="unit">万个</span>
              </div>
            </div>
            <div class="sonBox" style="min-width: 30%">
              <div class="title">驿马总计</div>
              <div class="nuber">
                {{ obj.YiMa ? keepTwoDecimalFull(obj.YiMa) : "--"
                }}<span class="unit">万匹</span>
              </div>
            </div>
            <div class="sonBox" style="min-width: 30%">
              <div class="title">官凭总计</div>
              <div class="nuber">
                {{
                  obj.rewardPoints
                    ? keepTwoDecimalFull(obj.rewardPoints)
                    : "--"
                }}<span class="unit">万个</span>
              </div>
            </div>
            <div class="sonBox" style="min-width: 30%">
              <div class="title">持有驿站</div>
              <div class="nuber">
                {{ obj.stage ? keepTwoDecimalFull(obj.stage) : "--"
                }}<span class="unit">万个</span>
              </div>
            </div>
            <div class="sonBox" style="min-width: 30%">
              <div class="title">待产出驿马</div>
              <div class="nuber">
                {{ obj.output ? keepTwoDecimalFull(obj.output) : "--"
                }}<span class="unit">万匹</span>
              </div>
            </div>
            <div class="sonBox" style="min-width: 30%">
              <div class="title">昨日释放驿马</div>

              <div class="nuber">
                {{
                  obj.release && Number(obj.release) > 0
                    ? keepTwoDecimalFull(obj.release)
                    : obj.release
                    ? keepTwoDecimalFull(-obj.release)
                    : "--"
                }}
                <span class="unit">万匹</span>
              </div>
            </div>
            <div class="sonBox" style="min-width: 30%">
              <div class="title">昨日消耗驿马</div>

              <div class="nuber">
                {{ obj.consume ? keepTwoDecimalFull(obj.consume) : "--" }}
                <span class="unit">万匹</span>
              </div>
            </div>
            <div class="sonBox" style="min-width: 30%">
              <div class="title">昨日产出驿马</div>

              <div class="nuber">
                {{
                  obj.outPutNumber ? keepTwoDecimalFull(obj.outPutNumber) : "--"
                }}
                <span class="unit">万匹</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="moduleBox"
          style="
            border-top: dashed 0.1rem #979797;
            border-bottom: dashed 0.1rem #979797;
          "
        >
          <div class="title">VIP总计</div>
          <div class="contentBox">
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title">普通VIP(人)</div>
              <div class="nuber">
                {{ obj.count1 ? obj.count1[0] : "--"
                }}<span class="unit"
                  >今日新增{{ obj.count1 ? obj.count1[1] : "0" }}人</span
                >
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title">大师VIP(人)</div>
              <div class="nuber">
                {{ obj.count2 ? obj.count2[0] : "--"
                }}<span class="unit"
                  >今日新增{{ obj.count2 ? obj.count2[1] : "0" }}人</span
                >
              </div>
            </div>
            <div
              class="sonBox"
              style="width: calc(50% - 0.3rem); margin-top: 1rem"
            >
              <div class="title">宗师VIP(人)</div>
              <div class="nuber">
                {{ obj.count3 ? obj.count3[0] : "--"
                }}<span class="unit"
                  >今日新增{{ obj.count3 ? obj.count3[1] : "0" }}人</span
                >
              </div>
            </div>
            <div
              class="sonBox"
              style="width: calc(50% - 0.3rem); margin-top: 1rem"
            >
              <div class="title">天师VIP(人)</div>
              <!-- <div class="nuberBox">
              <div>{{ obj.count3 ? obj.count3[0] : "--" }}</div>
              <div class="number">
                
              </div>
            </div> -->
              <div class="nuber">
                {{ obj.count4 ? obj.count4[0] : "--"
                }}<span class="unit"
                  >今日新增{{ obj.count4 ? obj.count4[1] : "0" }}人</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="moduleBox">
          <div class="title">本月统计数据</div>
          <div class="contentBox">
            <div class="sonBox">
              <div class="title">金叶子订单总额</div>
              <div class="nuberBox">
                <div>
                  {{
                    obj.JinYeZiNumber
                      ? keepTwoDecimalFull(obj.JinYeZiNumber)
                      : "--"
                  }}<span class="unit">万个</span>
                </div>
                <div class="number">今日{{ obj.JinYeZiCount || "--" }}单</div>
                <div class="number">
                  今日金叶子{{ obj.JinYeZiAmount || "--" }}个
                </div>
              </div>
            </div>
            <div class="sonBox">
              <div class="title">侠客集市订单总额</div>
              <div class="nuberBox">
                <div>
                  {{
                    obj.XiaoPaymentPrice
                      ? keepTwoDecimalFull(obj.XiaoPaymentPrice)
                      : "--"
                  }}<span class="unit">万元</span>
                </div>
                <div class="number">
                  今日{{ obj.XiaoPaymentCount || "--" }}单
                </div>
                <div class="number">
                  今日订单总额{{ obj.xiaoAmount || "--" }}元
                </div>
              </div>
            </div>
            <div class="sonBox">
              <div class="title">红尘坊订单总额</div>

              <div class="nuberBox">
                <div>
                  {{
                    obj.HongPaymentPrice
                      ? keepTwoDecimalFull(obj.HongPaymentPrice)
                      : "--"
                  }}<span class="unit">万元</span>
                </div>
                <div class="number">
                  今日{{ obj.HongPaymentCount || "--" }}单
                </div>
                <div class="number">
                  今日订单总额{{ obj.HongAmount || "--" }}元
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="moduleBox"
          style="
            border-top: dashed 0.1rem #979797;
            border-bottom: dashed 0.1rem #979797;
          "
        >
          <div class="title">金叶子持有人数</div>
          <div class="contentBox">
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">1000以下</div>
              <div class="nuberBox">
                <div>
                  {{ obj.number ? obj.number : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">1000-2000</div>
              <div class="nuberBox">
                <div>
                  {{ obj.number1 ? obj.number1 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">2000-4000</div>
              <div class="nuberBox">
                <div>
                  {{ obj.number2 ? obj.number2 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">4000以上</div>
              <div class="nuberBox">
                <div>
                  {{ obj.number3 ? obj.number3 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="moduleBox">
          <div class="title">驿马持有人数</div>
          <div class="contentBox">
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">500以下</div>
              <div class="nuberBox">
                <div>
                  {{ obj.quantity ? obj.quantity : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">500-1000</div>
              <div class="nuberBox">
                <div>
                  {{ obj.quantity1 ? obj.quantity1 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">1000-5000</div>
              <div class="nuberBox">
                <div>
                  {{ obj.quantity2 ? obj.quantity2 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">5000-10000</div>
              <div class="nuberBox">
                <div>
                  {{ obj.quantity3 ? obj.quantity3 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">10000-50000</div>
              <div class="nuberBox">
                <div>
                  {{ obj.quantity4 ? obj.quantity4 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">50000-100000</div>
              <div class="nuberBox">
                <div>
                  {{ obj.quantity5 ? obj.quantity5 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">100000以上</div>
              <div class="nuberBox">
                <div>
                  {{ obj.quantity6 ? obj.quantity6 : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="moduleBox" style="border-top: dashed 0.1rem #979797">
          <div class="title">用户统计</div>
          <div class="contentBox">
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">平台用户总数</div>
              <div class="nuberBox">
                <div>
                  {{ obj.totalQuantity ? obj.totalQuantity : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">本月新增用户</div>
              <div class="nuberBox">
                <div>
                  {{ obj.addPersonMonth ? obj.addPersonMonth : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">本日新增用户</div>
              <div class="nuberBox">
                <div>
                  {{ obj.addPersonDay ? obj.addPersonDay : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
            <div class="sonBox" style="width: calc(50% - 0.3rem)">
              <div class="title" style="font-size: 0.8rem">当日活跃用户</div>
              <div class="nuberBox">
                <div>
                  {{ obj.active ? obj.active : "--"
                  }}<span class="unit">人</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="contaienr" v-if="loading">
      <div class="loading">
        <van-loading type="spinner" />
      </div>
    </div>
  </div>
</template>
<script>
import { selectCount } from "@/api/platformData";
import { Toast } from "vant";
import url from "./platformDataBG.png";
export default {
  name: "platformData",
  data() {
    return {
      obj: {},
      loading: false,
      url,
    };
  },
  mounted() {
    this.getSelectCount();
  },
  methods: {
    getSelectCount() {
      this.loading = true;

      selectCount()
        .then((res) => {
          console.info(res);
          if (res.data.code == 0) {
            this.obj = res.data.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          Toast.fail("获取数据失败，请重新申请", err);
          this.loading = false;
        });
    },
    keepTwoDecimalFull(num) {
      // if (num > 10000) {
      let result = num / 10000;
      // result = Math.floor(result * 100) / 100;
         result = ((result * 100) / 100).toFixed(3);
      var s_x = result.toString(); //将数字转换为字符串
      var pos_decimal = s_x.indexOf("."); //小数点的索引值
      // 当整数时，pos_decimal=-1 自动补0
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      // 当数字的长度< 小数点索引+2时，补0
      while (s_x.length <= pos_decimal + 3) {
        s_x += "0";
      }
      // s_x += '万';
      // } else {
      //     s_x = num;
      // }
      console.info(s_x);
      return s_x;
    },
  },
};
</script>

<style lang="less" scoped>
#platformData {
  min-height: calc(100% - 2rem);
  width: calc(100% - 2rem);

  //   background-size: 100% 100%;
  //   background-image: url(../../assets/platformDataBG.png);
  padding: 1rem;
  background-color: #898989;
  // background-color: #4c4c4c;
  #bgBox {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  #contaienr {
    position: absolute;
    top: 0rem;
    width: 100%;
    height: 100%;
    left: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #title {
    font-size: 1.1rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #f1f1f1;
    line-height: 2.4rem;
    text-align: center;
    margin: 2rem 0 1rem;
  }
  .header {
    width: calc(100% - 3.7rem);
    background: #000000;
    border-radius: 1.692rem;
    opacity: 0.71;
    padding: 0.5rem 1.88rem 2rem;
    .moduleBox {
      & > .title {
        font-size: 0.98rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #8b8b8b;
        line-height: 1rem;
        // margin-bottom: 0.5rem;
        margin-top: 1.3rem;
      }
      .contentBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 1.4rem;

        .sonBox {
          margin-top: 0.8rem;
          margin-left: 0.3rem;
          .title {
            font-size: 0.7rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8b8b8b;
            line-height: 1.3rem;
            margin-bottom: 0.3rem;
          }
          .nuber {
            font-size: 1.2rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 1.3rem;
            .unit {
              font-size: 12px;
              transform: scale(0.9); /*缩到一半*/
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 1.3rem;
              display: inline-block;
            }
          }
          .nuberBox {
            font-size: 1.1rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 2rem;
            .unit {
              font-size: 12px;
              transform: scale(0.8); /*缩到一半*/
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 1.3rem;
              display: inline-block;
            }
            .number {
              font-size: 12px;
              transform: scale(0.8); /*缩到一半*/
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 1rem;
              margin-left: -0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
